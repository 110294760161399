// import React, { useState } from 'react';
import React from 'react';
import '../css/layout/Navbar.css';
import CurrentDateTime from './CurrentDateTime';
// import ThemeToggle from '../../contexts/ThemeToggle';


const Navbar = ({ toggleSidebar }) => {
  // const [userDetails, setUserDetails] = useState(null); 
  
  const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
  // console.log('Details:', storedUserDetails);

  // setUserDetails(storedUserDetails);
  // console.log(userDetails);

  const sessionValues = {
    userID: storedUserDetails.userID || 'Guest',
    userName: storedUserDetails.userName || 'Guest User',
    superUser: storedUserDetails.superUser || 'No',
  };

  const handleLogout = () => {
    // Clear session storage or perform logout logic
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/login'; // Redirect to login
  };

  return (
    <nav className="navbar">
      <div className="nav-brand">
        <span>CRM</span>
      </div>
      <div className="menu-icon" onClick={toggleSidebar}>
        <i className="tf-icons ri-menu-line"></i> {/* Menu icon */}
      </div>
      <div className="nav-search">
        <div className="input-group input-group-sm">
          <span className="input-group-text"><i className="tf-icons ri-search-line"></i></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
          />
        </div>
      </div>
      <CurrentDateTime />
      {/* <ThemeToggle /> */}
      <div className="profile-dropdown">
          <button className="profile-icon">👤</button>
          <div className="profile-popup">
          <div className="profile-details">
            <p>
              <strong>User ID:</strong> {sessionValues.userID}
            </p>
            <p>
              <strong>Name:</strong> {sessionValues.userName}
            </p>
            <p>
              <strong>Super User:</strong> {sessionValues.superUser}
            </p>
          </div>
          <div className="dropdown-divider"></div>
          <div className="d-grid px-4 pt-2 pb-1">
            <button style={{width: "100%"}}
              className="btn btn-danger d-flex logout-button"
              onClick={handleLogout}
            >
              <small className="align-middle">Logout</small>
              <i className="ri-logout-box-r-line ms-2 ri-16px"></i>
            </button></div>
        </div>
      </div>

      {/* <div className="nav-profile">
        <div className="profile-icon">
          <i className="bi bi-person-circle"></i>
        </div>
        <div className="profile-popup">
          <div className="profile-details">
            <p>
              <strong>User ID:</strong> {sessionValues.userID}
            </p>
            <p>
              <strong>Name:</strong> {sessionValues.userName}
            </p>
            <p>
              <strong>Super User:</strong> {sessionValues.superUser}
            </p>
          </div>
          <button
            className="logout-button"
            onClick={handleLogout}
          >
            Log Out
          </button>
        </div>
      </div> */}

    </nav>
  );
};

export default Navbar;